import React from "react"
import ReactHtmlParser from "react-html-parser"
import "./headsubheadhorizontal.scss"

export default function HeadSubheadHorizontal({
  children,
  subHeading,
  className = "",
  btnComponent = null,
  version = "v1",
  description = null,
  ...props
}) {
  return (
    <div className={`heading-subheading-horizontal ${className}`}>
      <div className="heading-subheading-horizontal-inner">
        <div className="">
          {subHeading && (
            <span className={`subheadingtop ${version}`}>
              {ReactHtmlParser(subHeading)}
            </span>
          )}
          <h1 {...props}>{ReactHtmlParser(children)}</h1>
          {btnComponent && btnComponent}
        </div>
        {description && (
          <div className="description">{ReactHtmlParser(description)}</div>
        )}
      </div>
    </div>
  )
}
